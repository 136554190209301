import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import Header from './../header/';
import Footer from './../footer/';
import useSiteMetadata from './../../components/SiteMetadata';
import style from './layouts.module.scss';

import { StaticQuery, graphql } from "gatsby";
import { IntlProvider } from 'react-intl';
import { getCurrentLangKey } from 'ptz-i18n';
import 'intl';

import LayoutEN from './en';
import LayoutNL from './nl';

export const LanguageContext = React.createContext(null);

const Layout = ({ children, langKey, i18nMessages, location }) => {
    const seo = useSiteMetadata(location.pathname);

    return (
        <IntlProvider
            locale={langKey}
            messages={i18nMessages}
        >
            <div className={style.layout}>
                <Helmet title={seo.title} titleTemplate={seo.titleTemplate}>
                    <html lang={langKey} />
                    <meta name="description" content={seo.description} />
                    <meta name="image" content={seo.image} />

                    <link
                        rel="apple-touch-icon"
                        sizes="180x180"
                        href="/images/apple-touch-icon.png"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/images/favicon-32x32.png"
                        sizes="32x32"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        href="/images/favicon-16x16.png"
                        sizes="16x16"
                    />

                    <link
                        rel="mask-icon"
                        href="/images/safari-pinned-tab.svg"
                        color="#ff4400"
                    />
                    <meta name="theme-color" content="#fff" />

                    {seo.url && <meta property="og:url" content={seo.url} />}
                    <meta property="og:type" content="business.business" />
                    {seo.title && <meta property="og:title" content={seo.title} />}
                    {seo.description && (
                        <meta property="og:description" content={seo.description} />
                    )}
                    {seo.image && <meta property="og:image" content={seo.image} />}
                    {seo.title && <meta name="twitter:title" content={seo.title} />}
                    {seo.description && (
                        <meta name="twitter:description" content={seo.description} />
                    )}
                    {seo.image && <meta name="twitter:image" content={seo.image} />}
                </Helmet>
                <Header />
                <div className={style.layoutMain}>{children}</div>
                <Footer />
            </div>
        </IntlProvider>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    data: PropTypes.array,
};

export const LanguageLayout = ({ children, location }) => {

    const renderLanguageSpecificLayout = (lng) => {
        if (lng === 'en') {
            return <LayoutEN langKey={lng} location={location}>{children}</LayoutEN>;
        }
        return <LayoutNL langKey={lng} location={location}>{children}</LayoutNL>;
    };

    return (
        <StaticQuery query={graphql`
            query LayoutQuery {
                site {
                    siteMetadata {
                        languages {
                            defaultLangKey
                            langs
                        }
                    }
                }
            }
      `}
         render={(data) => {
             const url = location.pathname;
             const { langs, defaultLangKey } = data.site.siteMetadata.languages;
             const langKey = getCurrentLangKey(langs, defaultLangKey, url);

             return (
                 <LanguageContext.Provider value={{ defaultLangKey, langKey, location }}>
                     {renderLanguageSpecificLayout(langKey)}
                 </LanguageContext.Provider>
             );
         }}
        />
    );
};

LanguageLayout.propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
    }),
};

export default Layout
