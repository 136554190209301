import React from 'react';
import PropTypes from 'prop-types';
import style from './content.module.scss';

const Content = (props) => (
    <div className={[style.content, props.className].join(' ')} dangerouslySetInnerHTML={{ __html: props.html}} />
);

Content.propTypes = {
    html: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default Content;
