import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './wrapperContent.module.scss';

import shape from './../../images/shapes-1.svg';

const WrapperContent = ({ children, className, color, component, overflow, artifacts, inline }) => (
    <div className={classNames(
        style.wrapperContent,
        (color === 'blue') ? style.wrapperContentBlue: null,
        (color === 'grey') ? style.wrapperContentGrey: null,
        (component) ? style.wrapperContentComponent : null,
        (overflow) ? style.wrapperContentOverflow : null,
        (inline) ? style.wrapperContentInline: null,
        className
    )}>
        <div className={style.wrapperInner}>
            { ((color === 'blue' || color === 'grey') && !component && artifacts) &&
            <>
                <span className={style.wrapperArtifactLeft} />
                <span className={style.wrapperArtifactRight} />
            </>
            }
            { ((color === 'blue' || color === 'grey') && component) &&
            <img src={shape} alt="shape" className={style.wrapperImage}/>
            }
            {children}
        </div>
    </div>
);

WrapperContent.defaultProps = {
    color: 'white',
    overflow: true,
    artifacts: true,
    inline: false,
};

WrapperContent.propTypes = {
    color: PropTypes.oneOf(['blue', 'white', 'grey']),
    component: PropTypes.bool,
    overflow: PropTypes.bool,
    artifacts: PropTypes.bool,
    inline: PropTypes.bool,
};

export default WrapperContent;
