module.exports = {
    'dutch': 'Nederlands',
    'english': 'English',
    '404.title': 'Page not found',
    '404.message': 'You just hit a route that does\'t exists.',
    'mainmenu.home': 'Home',
    'mainmenu.expertise': 'Expertise',
    'mainmenu.cases': 'Cases',
    'mainmenu.vacancies': 'Careers',
    'mainmenu.news': 'News',
    'mainmenu.about': 'About us',
    'mainmenu.url.cases': 'cases',
    'mainmenu.url.expertiss': 'expertise',
    'mainmenu.url.vacancies': 'careers',
    'mainmenu.url.news': 'news',
    'mainmenu.url.about': 'about-us',
    'select': 'Select',
    'results': '{itemCount, plural, =0 {No Results} one {result} other {rsults}}',
    'industryListTitle': 'We are proud on our customers',
    'activeIndustryListTitle' : 'Esito’s (active) industries',
    'noTags': 'No tags found',
    'showAllCases': 'Show all cases',
    'interestTitle': 'Also view',
    'sendMessage': 'Send a message',
    'call': 'Call',
    'mandatoryFormField': 'required',
    'cases.listCategoryMain': 'Industries',
    'vacancy.titleGezocht': "We are looking for...",
    'vacancy.titleAangeboden': 'We are offering...',
    'vacancy.contactLabel': 'Contact us',
    'vacancy.buttonBackLabel': 'Back to overview',
    'vacancy.hoursPerWeek': 'Hours per week',
    'vacancy.type': 'Type',
    'vacancy.expertise': 'Expertise',
    'vacancy.duration': 'Duration',
    'vacancy.listCategoryMain': 'expertise',
    'vacancy.listCategorySecondary': 'province',
    'newsletter.submitlabel': 'Signup',
    'newsletter.firstName': 'First name',
    'newsletter.lastName': 'Last name',
    'newsletter.email': 'Email',
    'newsletter.thanks': 'Thank you for your signup',
    'newsletter.error': 'Your signup has failed, please try again.',
    'news.readMoreLabel': 'Read more',
    'socialBar.default.socialMessage': 'I\'ve read this article, you may be interested as well: {url}',
    'socialBar.news.socialMessage': 'I\'ve read this article, you may be interested as well: {url}',
    'socialBar.vacancy.socialMessage': 'I found this vacancy, you may be interested as well: {url}',
    'socialBar.case.socialMessage': 'I found this case, you may be interested as well: {url}',
    'socialBar.expertise.socialMessage': 'I found this solution, you may be interested as well: {url}',
    'socialBar.whatsapp.label': 'Share with Whatsapp',
    'socialBar.linkedin.label': 'Share with LinkedIn',
    'socialBar.twitter.label': 'Share with Twitter',
    'socialBar.email.label': 'Share with email',
    'member.readMoreLabel': 'Read more +',
    'member.readLessLabel': 'Read less -',
    'contactTab.addressLabel': 'Address data',
    'contactTab.email': 'E-mail',
    'contactTab.phone': 'Phone',
    'contactTab.form.thanks': 'Thank you for contacting us. We will contact you as soon as possible.',
    'contactTab.form.submitLabel': 'Send',
    'form.errors.firstName': 'First name is required',
    'form.errors.lastName': 'Last name is required',
    'form.errors.email': 'E-mail is required',
    'form.errors.emailinvalid': 'Invalid e-mail given',
    'form.errors.comments': 'Comment is required',
    'form.errors.privacy': 'You need to confirm you agree with our privacy statement',
    'form.errors.file': 'File is too large ({fileSize}). You are allowed to upload a file of 1mb.',
    'contactTab.form.upload.label': 'File',
    'contactTab.form.upload.buttonLabel': 'Upload file',
    'form.gender': 'Exordium',
    'form.genderMale': 'Mr.',
    'form.genderFemale': 'Mrs.',
    'form.firstName': 'First name',
    'form.lastName': 'Last name',
    'form.email': 'E-mail address',
    'form.phone': 'Phone',
    'form.comment': 'Comment',
    'form.cvUpload': 'Add resume',
    'form.cvUploadButtonLabel': 'Upload file',
    'form.privacy': 'YES, i give permission to collect my personal information.',
    'form.disclaimer': 'Your personal information will never be shared with thirth parties. See our {link} how we deal with your information.',
    'form.displaimerLinkLabel': 'privacy statement',
    'form.submitLabel': 'Send',
    'form.vacancies.thanks': 'Thank you for contacting us. We will contact you as soon as possible.',
    'form.anchors.jobApplicationForm': 'apply-online',
    'homepage.allExpertisesButtonLabel': 'Show all expertises',
    'homepage.actueelTitle': 'Up-to-date',
    'homepage.showAllCasesButtonLabel': 'Show all cases',
    'homepage.showAllNewsButtonLabel': 'Show all news',
    'footer.privacyLink.url': '/en/privacy-statement/',
    'footer.privacyLink.label': 'Privacy statement',
    'footer.generalConditions.url': '/en/general-conditions/',
    'footer.generalConditions.label': 'General conditions'
};
