import React from 'react';
import Layout from './index';
import { addLocaleData } from 'react-intl';

import messages from './../../locales/translations/nl';
import nl from 'react-intl/locale-data/nl';
// import 'intl/locale-data/jsonp/nl';

addLocaleData(nl);

export default (props) => (
    <Layout
        {...props}
        i18nMessages={messages}
    />
);
