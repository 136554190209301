module.exports = {
    'dutch': 'Nederlands',
    'english': 'English',
    '404.title': 'Pagina niet gevonden',
    '404.message': 'Je bent op een url terecht gekomen die niet bestaat.',
    'mainmenu.home': 'Home',
    'mainmenu.expertise': 'Oplossingen',
    'mainmenu.cases': 'Cases',
    'mainmenu.vacancies': 'Carrière',
    'mainmenu.news': 'Nieuws',
    'mainmenu.about': 'Over ons',
    'mainmenu.url.cases': 'cases',
    'mainmenu.url.expertise': 'oplossingen',
    'mainmenu.url.vacancies': 'carriere',
    'mainmenu.url.news': 'nieuws',
    'mainmenu.url.about': 'over-ons',
    'select': 'Selecteer',
    'results': '{itemCount, plural, =0 {Geen resultaten} one {resultaat} other {resultaten}}',
    'industryListTitle': 'Wij zijn trots op onze klanten',
    'activeIndustryListTitle' : 'Esito’s (actieve) industrieën',
    'noTags': 'Geen tags gevonden',
    'showAllCases': 'Toon alle cases',
    'interestTitle': 'Bekijk ook',
    'sendMessage': 'Stuur een bericht',
    'call': 'Bel',
    'mandatoryFormField': 'verplicht',
    'cases.listCategoryMain': 'Industrie',
    'vacancy.titleGezocht': "Wij zoeken...",
    'vacancy.titleAangeboden': 'Wij bieden...',
    'vacancy.contactLabel': 'Neem contact op',
    'vacancy.buttonBackLabel': 'Terug naar overzicht',
    'vacancy.hoursPerWeek': 'Uur per week',
    'vacancy.type': 'Type',
    'vacancy.expertise': 'Expertise',
    'vacancy.duration': 'Duur',
    'vacancy.listCategoryMain': 'expertise',
    'vacancy.listCategorySecondary': 'provincie',
    'newsletter.submitlabel': 'Aanmelden',
    'newsletter.firstName': 'Voornaam',
    'newsletter.lastName': 'Achternaam',
    'newsletter.email': 'Email',
    'newsletter.thanks': 'Bedankt voor uw inschrijving',
    'newsletter.error': 'Uw inschrijving is niet gelukt, probeer het nogmaals.',
    'news.readMoreLabel': 'Lees meer',
    'socialBar.default.socialMessage': 'Ik heb dit bericht gelezen, leek mij interessant voor jou: {url}',
    'socialBar.news.socialMessage': 'Ik heb dit bericht gelezen, leek mij interessant voor jou: {url}',
    'socialBar.vacancy.socialMessage': 'Ik kwam deze vacature tegen, leek mij interessant voor jou: {url}',
    'socialBar.case.socialMessage': 'Ik kwam deze case tegen, leek mij interessant voor jou: {url}',
    'socialBar.expertise.socialMessage': 'Ik kwam oplossing tegen, leek mij interessant voor jou: {url}',
    'socialBar.whatsapp.label': 'Deel via Whatsapp',
    'socialBar.linkedin.label': 'Deel via LinkedIn',
    'socialBar.twitter.label': 'Deel via Twitter',
    'socialBar.email.label': 'Deel via email',
    'member.readMoreLabel': 'Lees meer +',
    'member.readLessLabel': 'Lees minder -',
    'contactTab.addressLabel': 'Adresgegevens',
    'contactTab.email': 'E-mail',
    'contactTab.phone': 'Telefoon',
    'contactTab.form.thanks': 'Bedankt voor uw bericht. Wij nemen zo spoedig mogelijk contact met u op.',
    'form.errors.firstName': 'Je hebt geen voornaam ingevuld',
    'form.errors.lastName': 'Je hebt geen achternaam ingevuld',
    'form.errors.email': 'Je hebt geen e-mail ingevuld',
    'form.errors.emailinvalid': 'Je hebt een ongeldig e-mailadres ingevuld',
    'form.errors.comments': 'Je hebt geen vraag (opmerking) ingevuld',
    'form.errors.privacy': 'Je dient akkoord te gaan met onze privacy verklaring',
    'form.errors.file': 'Bestand is te groot ({fileSize}). Je mag maximaal 1mb uploaden.',
    'contactTab.form.upload.label': 'Bestand',
    'contactTab.form.upload.buttonLabel': 'Upload bestand',
    'form.gender': 'Aanhef',
    'form.genderMale': 'Dhr.',
    'form.genderFemale': 'Mevr.',
    'form.firstName': 'Voornaam',
    'form.lastName': 'Achternaam',
    'form.email': 'E-mailadres',
    'form.phone': 'Telefoonnummer',
    'form.comment': 'Opmerking',
    'form.cvUpload': 'CV toevoegen',
    'form.cvUploadButtonLabel': 'Upload bestand',
    'form.privacy': 'JA, ik geef toestemming om mijn gegevens te verzamelen.',
    'form.disclaimer': 'Uw gegevens worden nooit aan derden verstrekt. Zie onze {link} om te zien hoe wij met uw gegevens omgaan.',
    'form.disclaimerLinkLabel': 'privacy verklaring',
    'form.submitLabel': 'Versturen',
    'form.vacancies.thanks': 'Bedankt voor uw bericht. Wij nemen zo spoedig mogelijk contact met u op.',
    'form.anchors.jobApplicationForm': 'solliciteer-online',
    'homepage.allExpertisesButtonLabel': 'Toon alle oplossingen',
    'homepage.actueelTitle': 'Actueel',
    'homepage.showAllCasesButtonLabel': 'Toon alle cases',
    'homepage.showAllNewsButtonLabel': 'Toon al het nieuws',
    'footer.privacyLink.url': '/privacy-verklaring/',
    'footer.privacyLink.label': 'Privacy verklaring',
    'footer.generalConditions.url': '/algemene-voorwaarden/',
    'footer.generalConditions.label': 'Algemene voorwaarden'
};
