import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import style from './wrapper.module.scss';

const Wrapper = ({ children, fullWidth, className }) => (
    <div className={classNames(
        style.wrapper,
        (fullWidth) ? style.fullWidth : null,
        className
    )}>{children}</div>
);

Wrapper.propTypes = {
    fullWidth: PropTypes.bool,
};

export default Wrapper;
