import React from 'react';
import PropTypes from 'prop-types';
import style from './grid.module.scss';

export default function Grid({children, direction, align, justify, wrap, className, gapLess, ...props}) {

    const getDirectionStyle = () => {
        switch (direction) {
            case 'row':
                return style.flexGridDirectionRow;
            case 'row-reverse':
                return style.flexGridDirectionRowReverse;
            case 'column':
                return style.flexGridDirectionColumn;
            case 'column-reverse':
                return style.flexGridDirectionColumnReverse;
            default:
                break;
        }
    };

    const getAlignStyle = () => {
        switch (align) {
            case 'flex-start':
                return style.flexGridAlignFlexstart;
            case 'flex-end':
                return style.flexGridAlignFlexend;
            case 'center':
                return style.flexGridAlignCenter;
            case 'baseline':
                return style.flexGridAlignBaseline;
            case 'stretch':
                return style.flexGridAlignStretch;
            default:
                break;
        }
    };

    const getJustifyStyle = () => {
        switch (align) {
            case 'flex-start':
                return style.flexGridJustifyFlexstart;
            case 'flex-end':
                return style.flexGridJustifyFlexend;
            case 'center':
                return style.flexGridJustifyCenter;
            case 'space-between':
                return style.flexGridJustifySpacebetween;
            case 'space-around':
                return style.flexGridJustifySpacearound;
            default:
                break;
        }
    }

    const cx = [
        style.flexGrid,
        direction ? getDirectionStyle() : null,
        align ? getAlignStyle() : null,
        justify ? getJustifyStyle() : null,
        wrap ? style.flexGridWrap: null,
        className ? className : null,
        gapLess ? style.flexGridGapless : null
    ].join(' ');

    return (
        <div {...props} className={cx}>
            {children}
        </div>
    );
}

Grid.propTypes = {
    children: PropTypes.node,
    align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch', '']),
    wrap: PropTypes.bool,
    direction: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse', '']),
    justify: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-between', 'space-around', '']),
    className: PropTypes.string,
    gapLess: PropTypes.bool,
};

Grid.defaultProps = {
    children: '',
    align: 'stretch',
    justify: '',
    wrap: false,
    direction: '',
    className: '',
    gapLess: false,
};
