import React from 'react';
import { injectIntl } from 'react-intl';
import { Link, graphql, useStaticQuery } from "gatsby";
import Container from './../../components/container';
import Grid from './../../components/grid';

import style from './footer.module.scss';
import { ReactComponent as Instagram } from './../../images/social/instagram.inline.svg';
import { ReactComponent as Linkedin } from './../../images/social/linkedin.inline.svg';
import { ReactComponent as Twitter } from './../../images/social/twitter.inline.svg';
import { ReactComponent as ArrowUp } from './../../images/arrowup.svg';
import {LanguageContext} from '../layouts';

const ExpertiseLink = ({ data }) => (
    <li>
        {data.slug &&
        <Link to={data.slug}>{data.title}</Link>
        }
        {!data.slug &&
        <span>{data.title}</span>
        }
    </li>
);

const Footer = ({ intl, ...rest }) => {
    const { langKey } = React.useContext(LanguageContext);
    const data = useStaticQuery(graphql`
    query footerData{
        footer: allMarkdownRemark(
            filter:{frontmatter: {
                templateKey: {eq: "configuration-footer"}
            }}) {
            edges {
                node {
                    frontmatter {
                        title
                        langKey
                        social {
                            linkedin
                            twitter
                        }
                        expertises {
                            expertisesTitle
                            expertisesList {
                                expertisesItemTitle
                            }
                        }
                    }
                    html
                    fields {
                      frontmattermd {
                        contactText {
                          html
                        }
                      }
                    }
                }
            }
        }
        expertises: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "expertise-page"}}}) {
            totalCount
            nodes {
              id
              fields {
                slug
                langKey
              }
              frontmatter {
                id
                title
              }
            }
        }
    }
    `);

    const dataFiltered = data.footer.edges.filter((item) => item.node.frontmatter.langKey === langKey);
    const expertisesLanguageFiltered = data.expertises.nodes.filter((item) => item.fields.langKey === langKey);
    const { frontmatter, html: body, fields } = dataFiltered[0].node;
    const date = new Date();
    const currentYear = date.getFullYear();

    let expertiseList = [];
    if (frontmatter.expertises && frontmatter.expertises.expertisesList) {
        expertiseList = frontmatter.expertises.expertisesList.map((item, index) => {
            const expertiseData = expertisesLanguageFiltered.find(t => t.frontmatter.id === item.expertisesItemTitle);
            return {
                key: index,
                title: (expertiseData) ? expertiseData.frontmatter.title : item.expertisesItemTitle,
                slug: (expertiseData) ? expertiseData.fields.slug : null,
            }
        });
    }

    return(
        <footer className={style.footer}>
            <Container className={style.footerContent}>
                <span className={style.backToTop} onClick={() => {window.scrollTo(0,0)}}><ArrowUp /></span>
                <Grid direction="row">
                    <Grid.Item xs={12} md={4}>
                        <h3 className={style.title}>{frontmatter.title}</h3>
                        <p dangerouslySetInnerHTML={{ __html: body}} />
                        {(frontmatter.social && frontmatter.social.instagram) &&
                            <a href={frontmatter.social.instagram} title="Instagram" className={style.socialLink}><Instagram /></a>
                        }
                        {(frontmatter.social && frontmatter.social.linkedin) &&
                            <a href={frontmatter.social.linkedin} title="Linkedin" className={style.socialLink}><Linkedin /></a>
                        }

                        {(frontmatter.social && frontmatter.social.twitter) &&
                            <a href={frontmatter.social.twitter} title="Twitter" className={style.socialLink}><Twitter /></a>
                        }
                        {(fields && fields.frontmattermd && fields.frontmattermd.contactText && fields.frontmattermd.contactText.html) &&
                            <p dangerouslySetInnerHTML={{__html: fields.frontmattermd.contactText.html}}/>
                        }
                    </Grid.Item>
                    <Grid.Item xs={12} md={6} mdOffset={2}>
                        {(frontmatter.expertises && frontmatter.expertises.expertisesTitle) &&
                        <h3 className={style.title}>{frontmatter.expertises.expertisesTitle}</h3>
                        }
                        <Grid direction="row" gapLess={true}>
                            <Grid.Item xs={12} md={6}>
                                <ul className={style.list}>
                                    {expertiseList.slice(0, Math.ceil(expertiseList.length /2)).map(item => <ExpertiseLink data={item} key={item.key} />)}
                                </ul>
                            </Grid.Item>
                            <Grid.Item xs={12} md={6}>
                                <ul className={style.list}>
                                    {expertiseList.slice(Math.ceil(expertiseList.length /2), expertiseList.length).map(item => <ExpertiseLink data={item} key={item.key} />)}
                                </ul>
                            </Grid.Item>
                        </Grid>
                    </Grid.Item>
                </Grid>
            </Container>
            <div className={style.footerBottom}>
                <Container>
                    &copy; {currentYear} Esito
                    <ul>
                        <li>
                            <Link to={intl.formatMessage({id: 'footer.privacyLink.url'})}>{intl.formatMessage({id: 'footer.privacyLink.label'})}</Link>
                        </li>
                        <li>
                            <Link to={intl.formatMessage({id: 'footer.generalConditions.url'})}>{intl.formatMessage({id: 'footer.generalConditions.label'})}</Link>
                        </li>
                    </ul>
                </Container>
            </div>
        </footer>
    )};

const FooterWithIntl = injectIntl(Footer);

export default FooterWithIntl;
