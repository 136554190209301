import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = (slug) => {
  const { site, meta } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
          }
        }
        meta: allMarkdownRemark(
          filter: {frontmatter: {templateType: {eq: "page"}}}
        ){
          nodes {
            frontmatter {
              metadata {
                excerpt
                metaDescription
                metaTitle
                metaImage
              }
            }
            fields {
              slug
            }
          }
        }
      }
    `);

  const metaData = meta.nodes.find(t => t.fields.slug === slug || t.fields.slug === `${slug}/`);

  if (metaData) {
    return {
      title: (metaData.frontmatter.metadata) ? metaData.frontmatter.metadata.metaTitle : site.siteMetadata.defaultTitle,
      titleTemplate: site.siteMetadata.titleTemplate,
      description: (metaData.frontmatter.metadata) ? metaData.frontmatter.metadata.metaDescription : site.siteMetadata.defaultDescription,
      image: (metaData.frontmatter.metadata && metaData.frontmatter.metadata.metaImage !== null) ? metaData.frontmatter.metadata.metaImage : `${site.siteMetadata.siteUrl}${site.siteMetadata.defaultImage}`,
      url: `${site.siteMetadata.siteUrl}${slug || "/"}`,
    }
  }

  return site.siteMetadata
};

export default useSiteMetadata
