import React from 'react';
import ClassNames from 'classnames';
import PropTypes from 'prop-types';
import style from './blendImage.module.scss';
import Img from 'gatsby-image';

const BlendImage = ({ src, alt, fluid, fixed, className, strength, ...rest }) => (
    <div className={
        ClassNames(
            style.blendImage,
            (typeof strength !== 'undefined') ? style[`strength${strength}`] : null,
            className
        )
    }>
        {(fluid && !fixed) &&
        <Img className={style.blendImageImg} fluid={src} fadeIn backgroundColor={true} alt="blendimage" />
        }
        {(fixed && !fluid) &&
        <Img className={style.blendImageImg} fixed={src} fadeIn backgroundColor={true} alt="blendimage" width={rest.width} height={rest.height}/>
        }
    </div>
);

BlendImage.propTypes = {
    fixed: PropTypes.bool,
    fluid: PropTypes.bool,
    src: PropTypes.object,
    alt: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export default BlendImage;
