import React from 'react';
import PropTypes from 'prop-types';
import style from './grid.module.scss';

export default function Item({children, grow, shrink, align, xs, sm, md, lg,xl, xsOffset, smOffset, mdOffset, lgOffset, xlOffset, flex, className, ...props }) {

    const getAlignStyle = () => {
        switch (align) {
            case 'flex-start':
                return style.flexGridItemAlignFlexstart;
            case 'flex-end':
                return style.flexGridItemAlignFlexend;
            case 'center':
                return style.flexGridItemAlignCenter;
            case 'baseline':
                return style.flexGridItemAlignBaseline;
            case 'stretch':
                return style.flexGridItemAlignStretch;
            default:
                break;
        }
    };

    const cx = [
        style.flexGridItem,
        grow ? style.flexGridItemGrow : null,
        shrink ? style.flexGridItemShrink : null,
        flex ? style.flexGridItemFlex : null,
        align ? getAlignStyle() : null,
        xs ? style[`flexGridItemxs${xs}`] : null,
        sm ? style[`flexGridItemsm${sm}`] : null,
        md ? style[`flexGridItemmd${md}`] : null,
        lg ? style[`flexGridItemlg${lg}`] : null,
        xl ? style[`flexGridItemxl${xl}`] : null,
        xsOffset ? style[`flexGridItemxsOffset${xsOffset}`] : null,
        smOffset ? style[`flexGridItemsmOffset${smOffset}`] : null,
        mdOffset ? style[`flexGridItemmdOffset${mdOffset}`] : null,
        lgOffset ? style[`flexGridItemlgOffset${lgOffset}`] : null,
        xlOffset ? style[`flexGridItemxlOffset${xlOffset}`] : null
    ].join(' ');

    return (
        <div {...props} className={cx}>
            {children}
        </div>
    );
}

Item.propTypes = {
    children: PropTypes.node,
    grow: PropTypes.bool,
    shrink: PropTypes.bool,
    flex: PropTypes.bool,
    align: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'baseline', 'stretch', '']),
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xsOffset: PropTypes.number,
    smOffset: PropTypes.number,
    mdOffset: PropTypes.number,
    lgOffset: PropTypes.number,
    className: PropTypes.string,
};

Item.defaultProps = {
    children: '',
    flex: false,
    grow: false,
    shrink: false,
    align: '',
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    className: '',
};
