import { useRef, useEffect, useState, useCallback } from 'react';

// Hook
export const useEventListener = (eventName, handler, element = window) => {
    // Create a ref that stores handler
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

// Hook for using a setInterval
// methods which can be used are:
// - resetInterval for resetting the interval.
// - toggleInterval for start/stop the interval (pause)
// - delay is the amount each tick is called, can be changed dynamicly.
export const useInterval = (callback, delay) => {
    const savedCallback = useRef();
    const intervalId = useRef(null);
    const [currentDelay, setDelay] = useState(delay);

    const toggleRunning = useCallback(
        () => setDelay(currentDelay => (currentDelay === null ? delay : null)),
        [delay]
    );

    const tick = () => {
        savedCallback.current();
    };

    const clear = useCallback(() => clearInterval(intervalId.current), []);

    const resetInterval = useCallback(
        () => {
            clear();
            intervalId.current = setInterval(tick, currentDelay);
        },
        [intervalId, clear, currentDelay]
    );

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {

        if (intervalId.current) clear();

        if (currentDelay !== null) {
            intervalId.current = setInterval(tick, currentDelay);
        }

        return clear;
    }, [currentDelay, clear]);

    return [resetInterval, toggleRunning, !!currentDelay];
};

export const useForm = (callback, validate) => {

    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState({status: false, form: null });

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting.status) {
            callback(isSubmitting.form);
        }
    });

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmitting({status: true, form: event.target });
    };

    const handleChange = (event) => {
        event.persist();

        if (event.target.type === 'file') {
            setValues(values => ({...values, [event.target.name]: event.target.files[0]}));
        } else if (event.target.type === 'checkbox') {
            setValues(values => ({...values, [event.target.name]: !values[event.target.name]}));
        } else {
            setValues(values => ({...values, [event.target.name]: event.target.value}));
        }
    };

    const resetField = (fieldName) => {
        setValues(values => ({...values, [fieldName]: null}));
    };

    const reset = () => {
        setIsSubmitting({status: false, form: null });
        setValues({});
        setErrors({});
    };

    return {
        handleChange,
        handleSubmit,
        reset,
        resetField,
        values,
        errors,
    }
};

// Hook
export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();

    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
