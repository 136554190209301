import React, { useContext, useCallback } from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import { Transition } from 'react-spring/renderprops';
import { useSpring, animated } from 'react-spring';
import { injectIntl } from 'react-intl';
import style from './header.module.scss';
import { useEventListener } from '../../hooks';
import Hamburger from './../../images/hamburger.svg';
import Close from './../../images/close.svg';
import Container from './../../components/container/';
import Counter from './../../components/counter';
// import LanguageSwitch from './../../components/languageSwitch/';
import logo from "../../images/logo-esito.svg";
import { LanguageContext } from '../layouts';

class Menu extends React.PureComponent {

    state = {
        menuActive: false,
    };

    handleMenuOpen = () => {
        this.setState({ menuActive: true });
    };

    handleMenuClose = () => {
        this.setState({ menuActive: false });
    };

    renderNavigation = () => {
        const { basePath, intl, vacancyCount } = this.props;

        return (<nav className={style.navMenuList}>
            <ul className={style.navbar}>
                <li className={style.navbarItem}><Link className={style.navbarLink} activeClassName={style.isActive} to={basePath}>{intl.formatMessage({id: 'mainmenu.home'})}</Link></li>
                <li className={style.navbarItem}><Link partiallyActive={true} className={style.navbarLink} activeClassName={style.isActive} to={`${basePath}${intl.formatMessage({id: 'mainmenu.url.expertise'})}`}>{intl.formatMessage({id: 'mainmenu.expertise'})}</Link></li>
                <li className={style.navbarItem}><Link partiallyActive={true} className={style.navbarLink} activeClassName={style.isActive} to={`${basePath}${intl.formatMessage({id: 'mainmenu.url.cases'})}`}>{intl.formatMessage({id: 'mainmenu.cases'})}</Link></li>
                <li className={style.navbarItem}><Link partiallyActive={true} className={style.navbarLink} activeClassName={style.isActive} to={`${basePath}${intl.formatMessage({id: 'mainmenu.url.vacancies'})}`}>{intl.formatMessage({id: 'mainmenu.vacancies'})}<Counter count={vacancyCount} /></Link></li>
                <li className={style.navbarItem}><Link partiallyActive={true} className={style.navbarLink} activeClassName={style.isActive} to={`${basePath}${intl.formatMessage({id: 'mainmenu.url.news'})}`}>{intl.formatMessage({id: 'mainmenu.news'})}</Link></li>
                <li className={style.navbarItem}><Link partiallyActive={true} className={style.navbarLink} activeClassName={style.isActive} to={`${basePath}${intl.formatMessage({id: 'mainmenu.url.about'})}`}>{intl.formatMessage({id: 'mainmenu.about'})}</Link></li>
            </ul>
        </nav>
    )};

    render() {
        const menuStyle = [style.navMenu, (this.state.menuActive) ? style.navMenuOpen : style.navMenuClosed].join(' ');

        return (
            <>
                <div className={style.mobile}>
                    <button className={style.hamburger} onClick={this.handleMenuOpen}><img src={Hamburger} alt="menu" /></button>
                    <Transition
                        items={this.state.menuActive}
                        from={{ opacity: 0 }}
                        enter={{ opacity: 0.4 }}
                        leave={{ opacity: 0 }}>
                        {show => show && (props => <div style={props} className={style.overlay}>show️</div>)}
                    </Transition>
                    <Transition
                        items={this.state.menuActive}
                        from={{ left: '-100%' }}
                        enter={{ left: '0%' }}
                        leave={{ left: '-100%' }}>
                        {show => show && (props => {
                            return (
                                <div id="navMenu" style={props} className={menuStyle}>
                                    <button className={style.close} onClick={this.handleMenuClose}><img src={Close} alt="Close" /></button>
                                    {/*<LanguageSwitch className={style.languageSwitch} />*/}
                                    {this.renderNavigation()}
                                </div>
                            );
                        })}
                    </Transition>
                </div>
                <div id="navMenu" className={[style.desktop, style.navMenu].join(' ')}>
                    {this.renderNavigation()}
                    {/*<LanguageSwitch className={style.languageSwitch} />*/}
                </div>
            </>
        );
    }
}

const MenuTranslatable = injectIntl(Menu);

const Header = () => {
    const language = useContext(LanguageContext);
    const basePath = (language.langKey === 'en') ? '/en/' : '/';
    const [{ scale }, setScale] = useSpring(() => ({scale: 1}));
    const test = scale.interpolate(o => `scale(${o})`);
    const data = useStaticQuery(graphql`
        query Header {
            vacanciesCountNL: allMarkdownRemark(filter: {frontmatter: { templateKey: { eq: "vacancy-page"}}, fields: { langKey: { eq: "nl"}}}) {
                totalCount
            }
            vacanciesCountEN: allMarkdownRemark(filter: {frontmatter: { templateKey: { eq: "vacancy-page"}}, fields: { langKey: { eq: "en"}}}) {
                totalCount
            }
        }
    `);

    const vacancyCount = (data[`vacanciesCount${language.langKey.toUpperCase()}`]) ? data[`vacanciesCount${language.langKey.toUpperCase()}`].totalCount : 0;

    const onScrollHandler = useCallback(
        (props) => {
            let percent = 1;

            if (window.innerWidth > 768) {
                if ((100 / 135) * props.target.documentElement.scrollTop < 100) {
                    percent = (1 / 135) * props.target.documentElement.scrollTop;
                } else {
                    percent = 1;
                }

                const e = 1 - (82 / 135); // 1 - (endHeight / startheight)
                setScale({scale: 1 - (e * percent)});
            }else {
                setScale({ scale: 1 });
            }
        },
        [setScale]
    );

    // we do need the document object. Since this ain't available on ssr the build will fail.
    // if (typeof document !== 'undefined') {
        useEventListener('scroll', onScrollHandler, (typeof document !== 'undefined') ? document : null);
    // }


    return(
        <header className={style.header}>
            <Container className={style.headerWrapper}>
                <span className={style.slogan}>Advies<br/>Implementatie<br/>Interim</span>
                    <Link to={basePath} className={style.logo} title="Logo">
                        <animated.div style={{ transform: test, transformOrigin: 'top left' }}>
                            <img src={logo} alt="Esito"  />
                        </animated.div>
                    </Link>
                <MenuTranslatable basePath={basePath} vacancyCount={vacancyCount}/>
            </Container>
        </header>
    );
};


export default Header;
