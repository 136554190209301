import React from 'react';
import style from './counter.module.scss';

const Counter = ({ count }) => {
    if (count === 0 || !count) {
        return null;
    }
    return (
        <span className={style.counter}>{count}</span>
    );
};

export default Counter;
