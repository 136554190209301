import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import style from './buttons.module.scss';

// ICONS
import { ReactComponent as ArrowRight } from './../../images/Icons/arrow-next.svg';
import { ReactComponent as ArrowLeft } from './../../images/Icons/arrow-back.svg';

const RenderIcon = (iconName) => {
    switch (iconName) {
        case 'arrowRight':
            return <ArrowRight/>;
        case 'arrowLeft':
            return <ArrowLeft/>;
        default:
            return null;
    }
};

export const ButtonIcon = ({ type, icon, className, disabled, onClick, ...rest }) => {
    if (type === 'GatsbyLink') {
        return (
            <Link className={[style.buttonIcon, (disabled) ? style.buttonIconDisabled : null, className].join(' ')} disabled={disabled} {...rest}>
                {RenderIcon(icon)}
            </Link>
        );
    }
    return (
        <button className={[style.buttonIcon, (disabled) ? style.buttonIconDisabled : null, className].join(' ')} onClick={(!disabled) ? onClick : null} {...rest}>
            {RenderIcon(icon)}
        </button>
    );
};

export const ButtonSubmit = ({ className, children, ...rest}) => (
    <button className={[style.buttonSubmit, className].join(' ')} {...rest}>
        {children}
    </button>
);

ButtonIcon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.oneOf(['arrowRight', 'arrowLeft']),
    disable: PropTypes.bool,
};

const Button = ({ primary, secondary, tertiary, cta, large, small, children, className, type, to, icon, ...rest}) => {
    const buttonStyle = [style.buttonStyle];

    if (primary && !secondary) buttonStyle.push(style.primary);
    if (secondary && !primary) buttonStyle.push(style.secondary);
    if (tertiary) buttonStyle.push(style.tertiary);
    if (cta) buttonStyle.push(style.cta);
    if (small && !large) buttonStyle.push(style.small);
    if (large && !small) buttonStyle.push(style.large);
    if (icon) buttonStyle.push(style.withIcon);
    if (className) buttonStyle.push(className);

    switch(type) {
        case "AHREF":
            return (
                <a href={to} className={buttonStyle.join(' ')} {...rest}>
                    {icon &&
                    <span className={style.icon}>{icon}</span>
                    }
                    <span className={style.label}>
                    {children}
                    </span>
                </a>
            );
        case "submit":
            return (
                <button type={type} className={buttonStyle.join(' ')} {...rest}>
                    {icon &&
                    <span className={style.icon}>{icon}</span>
                    }
                    <span className={style.label}>
                    {children}
                    </span>
                </button>
            );
        case "span":
            return (
                <span className={buttonStyle.join(' ')} {...rest} >
                    {icon &&
                    <span className={style.icon}>{icon}</span>
                    }
                    <span className={style.label}>
                    {children}
                    </span>
                </span>
            );
        default:
            return (
                <Link className={buttonStyle.join(' ')} to={to} {...rest}>
                    {icon &&
                    <span className={style.icon}>{icon}</span>
                    }
                    <span className={style.label}>
            {children}
            </span>
                </Link>
            );
    }
};

Button.defaultProps = {
    type: "GatsbyLink",
    primary: true,
    large: true,
    small: false,
};

Button.propTypes = {
    icon: PropTypes.node,
    showIconOnMobileOnly: PropTypes.bool,
    type: PropTypes.oneOf(["GatbsyLink", "AHREF", "submit", "span"]),
    primary: PropTypes.bool,
    secondary: PropTypes.bool,
    tertiary: PropTypes.bool,
    large: PropTypes.bool,
    small: PropTypes.bool
};

export default Button;


